<template>
  <div>
    <b-form class="form" @submit.stop.prevent="do_checkout">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-8 pb-0">
          <b-form-group label="เลือกขนส่งระหว่างประเทศ">
            <b-dropdown
              v-model="form.ship_by"
              :text="
                form.ship_by === 'ek'
                  ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                  : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
              "
            >
              <b-dropdown-item value="ek" @click="form.ship_by = 'ek'">
                ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
              </b-dropdown-item>
              <b-dropdown-item value="ship" @click="form.ship_by = 'ship'">
                ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
          <b-form-group id="input-group-1" label="หมายเหตุ">
            <b-form-textarea
              v-model="form.remark"
              size="sm"
              rows="4"
            ></b-form-textarea>
          </b-form-group>
          <div class="table-responsive">
            <table
              class="table table-borderless table-vertical-center"
              v-for="vendor in profile_cart"
              v-bind:key="vendor._id"
            >
              <thead>
                <tr>
                  <th class="pl-0 font-weight-bold text-muted d-flex">
                    <div class="text-overflow" style="width: 90px">
                      {{ vendor.vendor }}
                    </div>
                    <span class="ml-4 text-success" v-if="vendor.guarantee">
                      ตีลังไม้
                    </span>
                  </th>
                  <th class="text-center font-weight-bold text-muted">จำนวน</th>
                  <th class="text-right font-weight-bold text-muted">
                    ราคา({{ vendor.currencySign || "¥" }})
                  </th>
                  <th class="text-right pr-0 font-weight-bold text-muted">
                    รวม({{ vendor.currencySign || "¥" }})
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="font-weight-boldest"
                  v-for="item in vendor.items"
                  v-bind:key="item._id"
                >
                  <td class="pl-0 pt-6 pb-6 align-items-center align-middle">
                    <div class="d-flex">
                      <!--begin::Symbol-->
                      <a :href="item.url" target="_blank">
                        <div
                          class="symbol symbol-40 flex-shrink-0 mr-4 bg-light"
                        >
                          <div
                            class="symbol-label"
                            :style="
                              `background-image: url('${item.mainImage}')`
                            "
                          ></div>
                        </div>
                        <!--end::Symbol-->
                      </a>
                      <div>
                        <a :href="item.url" target="_blank">
                          {{ item.productName }}
                        </a>
                        <div
                          class="opacity-80 font-weight-bold d-block"
                          v-bind:key="c.id"
                          v-for="c in item.properties"
                        >
                          <span v-if="c.name">{{ c.name }} : </span>
                          <img
                            v-viewer
                            v-if="c.image"
                            :src="c.image"
                            style="height: 20px"
                            class="align-self-center pointer mr-2"
                            :alt="item.productName"
                          />
                          <span>{{ c.title }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pt-0 align-middle">
                    {{ format_number(item.amount) }}
                  </td>
                  <td class="text-right pt-0 align-middle">
                    {{ format_number(item.price, 2) }}¥
                  </td>
                  <td class="text-right pt-0 align-middle">
                    {{ format_number(item.price * item.amount, 2) }}¥
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="profile_cart && !profile_cart.length"
              class="text-center h5 pb-4"
            >
              ไม่มีข้อมูลสินค้า
            </div>
          </div>
        </div>
      </div>
      <div class="card card-custom px-6 py-4 card-stretch mt-4 align-center">
        <div class="d-flex">
          <div
            class="d-flex align-self-center fs-4"
            style="width: 200px; color: #f44336"
          >
            อัตราแลกเปลี่ยน
            {{ format_number(rate, 2) }}
          </div>
          <div class="d-flex ml-auto">
            <div
              class="mr-3 text-grey align-self-center fs-4"
              style="text-align: right"
            >
              <div class="text-danger">
                ***ฝากสั่งคิดเพิ่มคิวละ {{ this.serviceCube }} บาท หรือกิโลละ
                {{ this.serviceKg }} บาท จากเรทราคาปกติ
              </div>
              <div>
                สรุปยอด: ¥{{ format_number(total, 2) }} x ฿{{
                  format_number(rate, 2)
                }}
                <span v-if="totalBahtShopee">+ ฿{{ totalBahtShopee }}</span>
                =
                <span style="margin-left: 4px; font-size: 18px; color: #333">
                  {{ format_number(totalBaht, 2) }} บาท
                </span>
              </div>
            </div>
            <button
              class="btn btn-success ml-4"
              style="width: 144px"
              type="submit"
            >
              ยืนยันสั่งซื้อ
            </button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import bigDecimal from "js-big-decimal";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { CHECKOUT_ORDER } from "@/graphql/order";
import { UPDATE_CRAFTQC_CART } from "@/graphql/cart";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_CHECKOUT } from "@/core/services/store/checkout.module.js";
import { WEBSITE } from "@/config";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "CheckoutCart",
  created() {
    document.title = `ยืนยันรายการสั่งซื้อ | ${WEBSITE}`;
  },
  mounted() {
    const profile_cart = [];
    const checkout = this.checkout;
    const vendor = this.vendor;
    Object.keys(checkout).map(key => {
      profile_cart.push({
        qc: false,
        ...vendor[key],
        items: checkout[key].map(x => ({
          _id: x._id,
          productName: x.productName,
          url: x.url,
          amount: Number(x.amount),
          price: x.price,
          properties: x.properties,
          mainImage: x.mainImage,
          provider: x.provider,
          currency: x.currency,
          currencySign: x.currencySign,
          vendor: x.vendor,
          remark: x.remark
        }))
      });
    });
    if (profile_cart.length === 0) {
      this.$router.push({ name: "cart" });
      return;
    }
    let total = 0;
    let totalBahtShopee = 0;
    profile_cart.map(vendor => {
      const sum = vendor.items.reduce(
        (total, val) => total + (!val.currency ? val.price : 0) * val.amount,
        0
      );
      totalBahtShopee += vendor.items.reduce(
        (total, val) => total + (val.currency ? val.price : 0) * val.amount,
        0
      );
      total += sum;
    });
    const totalBaht = total * this.rateOrder;
    this.rate = this.rateOrder;
    this.totalBaht = totalBaht + totalBahtShopee;
    this.totalBahtShopee = totalBahtShopee;
    this.total = total;
    this.profile_cart = profile_cart;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตะกร้าสินค้า`, route: "/cart" },
      { title: `ยืนยันรายการสั่งซื้อ` }
    ]);
  },
  computed: {
    ...mapGetters([
      "checkout",
      "rateOrder",
      "vendor",
      "serviceCube",
      "serviceKg"
    ])
  },
  data() {
    return {
      total: 0,
      totalBaht: 0,
      totalBahtShopee: 0,
      profile_cart: {},
      rate: 0,
      form: {
        ship_by: "ek",
        remark: ""
      }
    };
  },
  validations: {
    form: {
      ship_by: {
        required
      },
      remark: {}
    }
  },
  methods: {
    resetForm() {
      this.form = {
        ship_by: null,
        remark: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async do_checkout() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการฝากสั่งซื้อสินค้า`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const ship_by = this.$v.form.ship_by.$model;
      const remark = this.$v.form.remark.$model;

      const {
        data: { checkoutOrder }
      } = await this.$apollo.mutate({
        mutation: CHECKOUT_ORDER,
        variables: {
          input: {
            remark,
            ship_by,
            vendors: this.profile_cart
          }
        }
      });
      if (checkoutOrder.error) {
        this.$message.error(checkoutOrder.message);
      } else {
        this.$store.dispatch(UPDATE_CHECKOUT, {
          selected: {},
          vendor: {},
          rateOrder: 0
        });
        this.$router.push({ name: "order_list" });
        this.$message.success(checkoutOrder.message);
      }
    },
    async enableWoodCart(vendorId, enable) {
      await this.$apollo.mutate({
        mutation: UPDATE_CRAFTQC_CART,
        variables: {
          input: {
            id: vendorId,
            key: "guarantee",
            val: enable ? 1 : 0
          }
        }
      });
      this.$apollo.queries.profile_cart.refetch();
    },
    format_number(val, decimal = 0) {
      return Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    }
  }
};
</script>
